import { useEffect, useState } from 'react'
import styles from './SardarCode.module.css'
// import { useSardarGetCodeMutation } from '../../../features/math/mathApiSlice'
import { useSocket } from '../../../app/SocketContext'
import { useSpeech } from "react-text-to-speech";

const SardarCode = () => {
    const socket = useSocket()
    const [code, setCode] = useState('') // Local state to hold the latest code
    const [text, setText] = useState('') // Local state to hold the latest text to speak

    // Fetch the initial code from the API once when the component mounts
    // useEffect(() => {
    //     sardarGetCode()
    // }, [sardarGetCode])


    useEffect(() => {
        // Emit request for code after the client connects
        socket.emit('request_code_update', { actionType: 'request_code_update' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleConnect = () => {
            socket.emit('request_code_update', { actionType: 'request_code_update' });
        };
    
        // Listen for the socket connection
        socket.on('connect', handleConnect);
    
        // Cleanup the event listener
        return () => {
            socket.off('connect', handleConnect);
        };
    }, [socket]);
    

    // Set up the socket listener to receive the latest code directly
    useEffect(() => {
        // Listen for the 'code_update' event and update the code state with the new code
        socket.on('code_update', (newCode) => {
            setCode(newCode) // Directly update code without additional API call
        })
        socket.on('send_voice', (speech) => {
            setText(speech) // Update text to speak
        })

        // Cleanup on component unmount
        return () => {
            socket.off('code_update')
        }
    }, [socket])

    const CodeDisplay = ({ code }) => {
        const codeDigits = (code ? code.padEnd(6, ' ').split('') : Array(6).fill(''))

        return (
            <div className={styles.row}>
                {codeDigits.map((digit, index) => (
                    <div key={index} className={styles.codeSquare}>
                        {digit.trim() || ''}
                    </div>
                ))}
            </div>
        )
    }

    // Initialize speech settings
    const { start } = useSpeech({
        lang: "ru-RU",
        voiceURI: "com.apple.voice.compact.ru-RU.Milena",
        text: text
    });

    // Trigger speech only if it hasn't been spoken yet
    
    useEffect(() => {
        start(); // Start speech when 'text' is set     
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [text]);

    return (
        <div>
            {/* Display code from socket or fallback to initial API data */}
            <CodeDisplay code={code} />
        </div>
    )
}

export default SardarCode
