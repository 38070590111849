import styles from './SLayout.module.css'
import { useEffect, useRef, useState } from 'react'
import { Link, Outlet } from "react-router-dom"
// 
import SNav from './common/SNav'
import SFooter from "./common/SFooter"
// Socket IO
import { SocketProvider } from '../../app/SocketContext'


// Helmet
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';


const SLayout = () => {
    const isDefaultThemeColor = useSelector(state => state.helmet.theme_color.isDefault);
    const defaultThemeColor = useSelector(state => state.helmet.theme_color.defaultColor);
    const newThemeColor = useSelector(state => state.helmet.theme_color.newColor);


    const content = (
        <>
            {isDefaultThemeColor
                ?   <Helmet>
                    <meta name="theme-color" content={defaultThemeColor} />
                    </Helmet>
                : <Helmet>
                        <meta name="theme-color" content={newThemeColor} />
                    </Helmet>
            }
            
            <div className={styles.v_layout}>
                    <SocketProvider>
                        <SNav />
                        <Outlet />
                        <SFooter />
                    </SocketProvider>
                    {/* <div className={styles.main}>
                        <p className={styles.text}>Server Time: {formatTime(serverTime)}</p>
                    
                        <p className={styles.text}>Author: Marat Shakeshev [Version 1.0.234.5678]</p>
                        <p className={styles.text}>(c) ArcTeach. All rights reserved.</p>
                        
                        <p className={`${styles.text} ${styles.caret}`}>
                        // C:\{`>`}
                        (admin) shakeshev@Marats-MacBook-Pro ~ % 
                        </p>  
                    </div>   */}
            </div>
        </>
    )

    return content
}

export default SLayout