import styles from './SNav.module.css'

const SNav = () => {
  return (
            <nav className={styles.nav}>
                <div className={styles.left}>Left</div>
                <div className={styles.middle}>Middle</div>
                <div className={styles.right}>Right</div>
            </nav>
    )
}

export default SNav