import React, { useEffect, useRef, useState, useMemo } from 'react';
import styles from './ExCountdownLayout.module.css'
// redux
import { useGetProblemQuery, usePostProblemMutation } from '../../../../features/math/mathApiSlice';
import { MathJax } from 'better-react-mathjax';
// Timer
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

const ExCountdownLayout = ({ exercise_id, timeInSec }) => {
    // POST
    const [postProblem, {
        data: post,
        isLoading, 
        isSuccess, 
        isUninitialized,
        isError, 
        error
    }] = usePostProblemMutation();

    const Left = ({ post }) => {
        const Records = () => {
            if(post?.correctAnswers){
                return post?.correctAnswers.map( (record, index) => {
                    return  <MathJax key={index} className={`${styles.record_block} ${styles.correct}`}>
                                <p className={styles.record_problem}>{record?.question}</p>
                                <span className={styles.record_timeSpent}>{record?.timeSpent}</span>
                            </MathJax>
                })
            }
            
        }
        return <div className={styles.left}>
                <span className={styles.correct_label}>Correct: {post?.correctAnswers.length}</span>
                <Records />
        </div>;
    };

    const Middle = ({ postProblem, post, isLoading, isUninitialized }) => {

        const [userAnswer, setUserAnswer] = useState('');
        const [result, setResult] = useState('');
        const inputRef = useRef(null);

        useEffect(() => {
            inputRef.current.focus();
            if(isUninitialized){
                // Focus the input field when the component mounts
                postProblem({
                    exercise_id: exercise_id
                });
            }
        }, []);

        const checkAnswer = () => {
            if(userAnswer.trim().length === 0){
                setUserAnswer('')
                return
            }
            postProblem({
                exercise_id: exercise_id,
                answer: userAnswer,
                question_id: post.id
            });
            setUserAnswer(''); // Clear the input if the answer is incorrect
            inputRef.current.focus(); // Focus the input field after checking the answer
        };

        useEffect(() => {
            if (post && isSuccess) {
                if (post.correct) {
                    setResult('Correct!');
                } else {
                    setResult('Incorrect, try again.');
                }
            }
        }, [post]);

        const handleKeyPress = (e) => {
            if (e.key === 'Enter') {
                checkAnswer(); // Trigger checkAnswer on Enter key press
            }
        };

        const [isTypeset, setIsTypeset] = useState(false);
        const handleTypeset = () => {
            setIsTypeset(true);
        };

        const UrgeWithPleasureComponent = useMemo(() => (
            <CountdownCircleTimer
                isPlaying
                duration={timeInSec}
                isGrowing={false}
                rotation='counterclockwise'
                colors={['#FFD702', '#a80808', '#ff0000']}
                trailColor='#3e3e3e'
                trailStrokeWidth={2}
                strokeWidth={3}
                colorsTime={[7, 3, 0]}
                isSmoothColorTransition={false}
                size={80}
                onComplete={() => {
                    // do your stuff here
                    postProblem({
                        exercise_id: exercise_id,
                        answer: '-',
                        question_id: post.id
                    });
                    // 
                    return { shouldRepeat: true, delay: 0 }; // repeat animation
                }}
            >
                {({ remainingTime }) => {
                    
                    return <span className={`${styles.timer_label} ${remainingTime > 3 ? styles.label_yellow : styles.label_red} `}>
                            {Math.floor(remainingTime)}
                        </span>
                }}
            </CountdownCircleTimer>
        ), []); // The empty dependency array ensures this is memoized and won't re-render.

        return (
            <div className={styles.middle}>
                <div className={styles.stats}>
                    <span className={styles.remainingQuestions}>Left: {post?.remainingQuestions}</span>
                    <div className={styles.timer}>
                        {isTypeset && <div className="loader">
                            {UrgeWithPleasureComponent}    
                        </div>}
                        
                    </div>
                </div>

                <label className={styles.problem_desc}>Find the value</label>
                {!isTypeset && <div className="loader">Loading Math...</div>}
                {post?.question && !isLoading && (
                    <MathJax className={styles.problem_text} 
                        onTypeset={handleTypeset} 
                        hideUntilTypeset="first"
                    >
                        {post?.question}
                    </MathJax>
                )}

                <input
                    className={styles.problem_field}
                    ref={inputRef}
                    type="text"
                    value={userAnswer}
                    onChange={(e) => setUserAnswer(e.target.value)}
                    onKeyDown={handleKeyPress} // Add key press event listener
                />
                <button className={styles.btn_check} onClick={checkAnswer}>Check Answer</button>
                {result && <p>{result}</p>}
            </div>
        );
    };

    const Right = () => {
        const Records = () => {
            if(post?.wrongAnswers){
                return post?.wrongAnswers.map( (record, index) => {
                    return  <MathJax key={index} className={`${styles.record_block} ${styles.wrong}`}>
                                    <p className={styles.record_problem}>{record?.question}</p>
                                    <span className={styles.record_timeSpent}>{record?.timeSpent}</span>
                                    <span className={styles.wrongCounter}>x{record?.wrongCounter}</span>
                            </MathJax>

                    
                })
            }
            
        }
        return <div className={styles.left}>
                <span className={styles.correct_label}>Wrong: {post?.wrongAnswers.length}</span>
                <Records />
        </div>;
    };
    
    return (
        <div className={styles.section}>
            <Left post={post} />
            <Middle postProblem={postProblem} post={post} isLoading={isLoading} isUninitialized={isUninitialized} />
            <Right />
        </div>
    );
};

export default ExCountdownLayout;
