import styles from './VLogin.module.css'
import { useNavigate } from 'react-router-dom'
// Form
import { useForm } from "react-hook-form"
// Redux
import { useDispatch } from 'react-redux'
import { useLoginMutation } from '../../../../features/auth/authApiSlice'
import { setCredentials } from '../../../../features/auth/authSlice'
// Images

const VLogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [login, { isLoading }] = useLoginMutation()

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm({
        mode: "onSubmit",
        // defaultValues: {
        //     email: 'shm90@mail.ru',
        //     password: '123456'
        // }
    })
    
    const onSubmit = async (data) => {
        try {
          const { email, password } = data
          const { accessToken } = await login({ email, password }).unwrap()
          console.log(1)
          dispatch(setCredentials({ accessToken }))
          reset()
          navigate('/library/math')
        } catch (error) {
          console.log(error)
        }
        
    }

    return (<section className={styles.login_block}>
        <div className={styles.wrapper}>
            <form className={styles.formLogin} onSubmit={handleSubmit(onSubmit)}>
                        <div className={styles.title}>Login</div>
                        
                        <div className={styles.form_loginFieldsBlock}>
                            <div className={styles.form_loginFields_row}>
                                <label className={styles.input_label} htmlFor='email'>Username<span className={styles.text_red}>*</span></label>
                                <input
                                    type='text'
                                    className={styles.form_inputField}
                                    id='email'
                                    placeholder='Эл. почта'
                                    {
                                    ...register("email",
                                    { 
                                        required: "Email is required",
                                        pattern:  {
                                                    value: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
                                                    message: "Not valid email"
                                                }  
                                    }                      
                                    )}
                                    aria-invalid={errors.email ? "true" : "false"}
                                />
                            </div>  
                            <p>{errors.email?.message}</p>

                            <div className={styles.form_loginFields_row}>
                                <label className={styles.input_label} htmlFor='password'>Password<span  className={styles.text_red}>*</span></label>
                                <input
                                    type='password'
                                    placeholder='Пароль'
                                    id='password'
                                    className={styles.form_inputField}
                                    {...register("password", 
                                    { 
                                        required: "Password is required" 
                                    }
                                    )}
                                    aria-invalid={errors.password ? "true" : "false"}
                                />
                            </div>
                            {errors.password?.type === "required" && (
                            <p>{errors.password?.message}</p>
                            )}
                        </div>

                        <button className={styles.btn_login}>
                        Войти
                        </button>

            </form>
        </div>
                
            </section>)
}

export default VLogin