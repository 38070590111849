import React, { createContext, useContext, useEffect } from 'react'
import io from 'socket.io-client'

const SocketContext = createContext()

export const useSocket = () => useContext(SocketContext)

export const SocketProvider = ({ children }) => {
    const socket = io(process.env.REACT_APP_BASE_URL) // Replace with your server URL

    useEffect(() => {
        return () => socket.disconnect() // Disconnect on cleanup
    }, [socket])

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    )
}
